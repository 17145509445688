<template>
  <button><i class="fa-solid fa-pen"></i></button>
</template>

<script>
export default {
  name: "EditInputButton",
};
</script>

<style lang="scss" scoped>
button {
  width: 2rem;
  height: 2rem;
  padding: 0;
  border: 1px solid $main_gray;
  border-radius: 50%;
  background-color: transparent;
  &:hover {
    background-color: $gray_hover;
  }
  transition: 0.2s;
  i {
    font-size: 1.2rem;
    color: #000;
  }
}
</style>
