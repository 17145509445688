<template>
  <div class="member input_form">
    <el-form @submit.prevent :model="ruleForm" :rules="rules" ref="ruleForm" :label-position="labelPosition">
      <div class="main">
        <div class="main_data">
          <div class="avatar_section">
            <div class="d-flex align-items-center mb-3">
              <div class="label avatar_img me-3">頭貼</div>
            </div>
            <div class="reset">
              <el-upload class="avatar-uploader" action="" :http-request="uploadAvatar" :show-file-list="false" :on-success="handleAvatarSuccess">
                <img v-if="ruleForm.imageUrl" :src="ruleForm.imageUrl" class="avatar" />
                <i class="fa-solid fa-plus avatar-uploader-icon" v-else></i>
              </el-upload>
              <el-button class="reset_btn" @click="resetAvatar">重設頭貼</el-button>
            </div>
          </div>
          <div v-if="!$store.getters.mobileSize" class="line mt-4">
            <label>綁定第三方登入</label>
            <div class="other_login">
              <div :class="{ icon_part: true, verified: currentUser().isLineVerified }">
                <i v-if="currentUser().isLineVerified" class="fa-solid fa-check"></i>
                <img class="otherBtn" @click="currentUser().isLineVerified ? null : lineLogin()" :src="require('../../../assets/image/Shared/Login/Single_Line.png')" />
              </div>
              <div :class="{ icon_part: true, verified: currentUser().isGoogleVerified }">
                <i v-if="currentUser().isGoogleVerified" class="fa-solid fa-check"></i>
                <img class="otherBtn" @click="currentUser().isGoogleVerified ? null : loginWithGoogle()" :src="require('../../../assets/image/Shared/Login/Single_Google.png')" />
              </div>
              <!-- <div :class="{ icon_part: true, verified: currentUser().isFaceBookVerified }">
                <i v-if="currentUser().isFaceBookVerified" class="fa-solid fa-check"></i>
                <img class="otherBtn" @click="currentUser().isFaceBookVerified ? null : loginWithFaceBook()" :src="require('../../../assets/image/Shared/Login/Single_Facebook.png')" />
              </div> -->
            </div>
          </div>
          <div v-if="!$store.getters.mobileSize">
            <PasswordEdit v-if="ruleForm.isPasswordSetting" />
            <PasswordSetting @changePasswordSetting="ruleForm.isPasswordSetting = true" v-else />
          </div>
        </div>
        <div v-if="$store.getters.mobileSize" class="line mt-4">
          <label>綁定第三方登入</label>
          <div class="other_login">
            <div :class="{ icon_part: true, verified: currentUser().isLineVerified }">
              <i v-if="currentUser().isLineVerified" class="fa-solid fa-check"></i>
              <img class="otherBtn" @click="currentUser().isLineVerified ? null : lineLogin()" :src="require('../../../assets/image/Shared/Login/Single_Line.png')" />
            </div>
            <div :class="{ icon_part: true, verified: currentUser().isGoogleVerified }">
              <i v-if="currentUser().isGoogleVerified" class="fa-solid fa-check"></i>
              <img class="otherBtn" @click="currentUser().isGoogleVerified ? null : loginWithGoogle()" :src="require('../../../assets/image/Shared/Login/Single_Google.png')" />
            </div>
            <!-- <div :class="{ icon_part: true, verified: currentUser().isFaceBookVerified }">
                <i v-if="currentUser().isFaceBookVerified" class="fa-solid fa-check"></i>
                <img class="otherBtn" @click="currentUser().isFaceBookVerified ? null : loginWithFaceBook()" :src="require('../../../assets/image/Shared/Login/Single_Facebook.png')" />
              </div> -->
          </div>
        </div>
        <el-tabs class="mt-4 mt-lg-0 infoSection" type="border-card" v-model="activeName">
          <el-tab-pane label="基本資料" name="baseInfo">
            <div class="name">
              <el-form-item prop="name" label="姓名">
                <el-input @click="changeToEdit(1)" ref="autoFocus1" v-model="ruleForm.name" @keydown.enter.prevent="editCompleted(1)" placeholder="請輸入姓名" :readonly="!isProfileClickedArray[1]" :class="{ disActive: !isProfileClickedArray[1] }" />
                <div class="edit_group">
                  <EditInputButton @click="changeToEdit(1)" v-if="!isProfileClickedArray[1]" />
                  <EditButtonGroup v-else @cancel="changeToOrigin(1)" @confirm="editCompleted(1)" />
                </div>
              </el-form-item>
            </div>
            <div class="referralCode onlyText">
              <div class="">
                <div class="referralCodeURL">會員等級</div>
              </div>
              <el-form-item>
                <span>{{ memberLevelObject[ruleForm.memberLevel] }}</span>
              </el-form-item>
            </div>
            <div class="email">
              <label>信箱 <span v-if="!currentUser().isEmailVerified"> &nbsp; (綁定即可使用Emal登入)</span></label>
              <div>
                <el-button v-if="!currentUser().isEmailVerified" class="mb-3 mt-2" @click="dialogOpenEmailVisible = true" type="primary" plain>綁定Email</el-button>
                <div v-else>
                  <el-form-item prop="email">
                    <el-input v-model="ruleForm.email" placeholder="請輸入電子信箱" readonly="true" class="disActive" />
                  </el-form-item>
                </div>
              </div>
            </div>
            <!-- <div class="cellPhone">
              <label>手機 <span class="red_notice" v-if="isShowPhoneVerification">(尚未驗證)</span></label>
              <el-form-item prop="cellPhone">
                <el-input ref="autoFocus3" v-model="ruleForm.cellPhone" @keydown.enter.prevent="editCompleted(3)" placeholder="請輸入手機號碼" :readonly="!isShowPhoneVerification || !isProfileClickedArray[3]" :class="{ disActive: !isProfileClickedArray[3] }" />
                <div class="edit_group" v-if="isShowPhoneVerification">
                  <EditInputButton @click="changeToEdit(3)" v-if="!isProfileClickedArray[3]" />
                  <EditButtonGroup v-else @cancel="changeToOrigin(3)" @confirm="editCompleted(3)" />
                </div>
              </el-form-item>
              <div class="text-end">
                <div v-if="isShowPhoneVerification">
                  <el-button size="small" type="primary" round @click="verifyMember(1)" plain>發送驗證簡訊</el-button>
                  <EditButton text="已發送驗證？" @click="showDialogPhone"></EditButton>
                </div>
                <div v-else class="verify_success"><i class="fa-solid fa-circle-check me-1"></i><span>手機認證成功</span></div>
              </div>
            </div> -->
            <div class="birthday">
              <el-form-item label="生日">
                <div>
                  <el-date-picker v-if="isProfileClickedArray[4]" v-model="ruleForm.birthday" type="date" value-format="YYYY-MM-DD" placeholder="請輸入生日" :readonly="!isProfileClickedArray[4]" :class="{ active: !isProfileClickedArray[4] }"></el-date-picker>
                  <p class="birthdayText" @click="changeToEdit(4)" v-else><i class="fa-light fa-calendar"></i>{{ birthday }}</p>
                </div>
                <div class="edit_group">
                  <EditInputButton @click="changeToEdit(4)" v-if="!isProfileClickedArray[4]" />
                  <EditButtonGroup v-else @cancel="changeToOrigin(4)" @confirm="editCompleted(4)" />
                </div>
              </el-form-item>
            </div>
            <div class="memberShipExpired onlyText">
              <el-form-item label="會籍到期日">
                <span :class="{ expired: !membershipExpired }">{{ ruleForm.membershipExpiredDateTime }}</span>
              </el-form-item>
            </div>
            <!-- 暫時註解，之後會用 -->
            <!-- <div class="referralCode onlyText">
              <div class="">
                <div class="referralCodeURL">推薦碼網址<el-button class="ms-2" v-if="ruleForm.isBuyMembership" size="small" type="primary" round @click="copyText()" plain>複製推薦碼網址</el-button></div>
                <p v-if="!ruleForm.isBuyMembership" class="referralCode_notice">(購買會籍後，即可擁有推薦碼網址，分享賺取ChargeMe點數)</p>
              </div>
              <el-form-item v-if="ruleForm.isBuyMembership">
                <span>{{ ruleForm.referralCode }}</span>
              </el-form-item>
            </div> -->
            <div class="referralCode onlyText">
              <div class="">
                <div class="referralCodeURL">推薦碼網址<el-button class="ms-2" size="small" type="primary" round @click="copyText()" plain>複製推薦碼網址</el-button></div>
              </div>
              <el-form-item>
                <span>{{ ruleForm.referralCode }}</span>
              </el-form-item>
            </div>
            <div class="promoCode onlyText">
              <el-form-item label="優惠碼">
                <el-input class="me-2" v-model="promoCode" placeholder="請輸入優惠碼" />
                <el-button class="px-4" @click="sendPromoCode()">確認</el-button>
              </el-form-item>
            </div>
          </el-tab-pane>
          <el-tab-pane label="點數資訊" name="pointInfo">
            <div class="lineBot">
              <div class="lineBotPoint onlyText">
                <el-form-item label="Line通知剩餘點數">
                  <span>{{ ruleForm.lineBotPoint }} 點</span>
                </el-form-item>
              </div>
              <div class="LineBotRequestCount onlyText">
                <el-form-item label="Line呼叫總次數">
                  <span>{{ ruleForm.lineBotRequestCount }} 次</span>
                </el-form-item>
              </div>
            </div>
            <div class="chargeMePoint onlyText">
              <el-form-item label="ChargeMe點數">
                <span>{{ ruleForm.chargeMePoint }} 點</span>
              </el-form-item>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-form>
    <div v-if="$store.getters.mobileSize">
      <PasswordEdit v-if="ruleForm.isPasswordSetting" />
      <PasswordSetting @changePasswordSetting="ruleForm.isPasswordSetting = true" v-else />
    </div>
  </div>
  <el-dialog v-model="dialogEmailVisible" title="Email認證" :width="$store.getters.mobileSize == true ? '75%' : '50%'">
    <el-input class="verify_input verify_input_mail" v-model="verifyEmail" @keydown.enter.prevent="verifyConfirm(0)" placeholder="請輸入驗證碼" autocomplete="off" />
    <div class="email_warning">
      <i class="fa-solid fa-circle-info"></i>
      請至信箱收取驗證信，並將驗證碼填入送出，完成Email認證！
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="verifyCancel(0)">取消</el-button>
        <el-button type="primary" @click="verifyConfirm(0)">確認</el-button>
      </span>
    </template>
  </el-dialog>
  <el-dialog v-model="dialogPhoneVisible" title="手機認證">
    <el-input class="verify_input verify_input_phone" v-model="verifyPhone" @keydown.enter.prevent="verifyConfirm(1)" placeholder="請輸入驗證碼" autocomplete="off" />
    <div class="email_warning">
      <i class="fa-solid fa-circle-info"></i>
      請至手機收取驗證簡訊，並將驗證碼填入送出，完成手機認證！
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="verifyCancel(1)">取消</el-button>
        <el-button type="primary" @click="verifyConfirm(1)">確認</el-button>
      </span>
    </template>
  </el-dialog>

  <!-- 綁定Email -->
  <el-dialog v-model="dialogOpenEmailVisible" @close="verifyEmailClear()" title="綁定Email" :width="$store.getters.mobileSize == true ? '75%' : '50%'">
    <el-form :model="emailForm" :rules="rules" ref="emailForm">
      <div class="email_section">
        <div class="email">
          <label class="red_star">* <span>設定Email(請至信箱收取驗證信)</span></label>
          <div class="d-flex align-items-center">
            <el-form-item prop="email" class="mb-0 w-100">
              <el-input :disabled="isVerifyCode" type="email" class="inner-input" ref="autoFocus" v-model="emailForm.email" autocomplete="on" placeholder="請輸入Email" />
            </el-form-item>
            <el-button class="ms-2" plain @click="verifyMemberEmail()" :disabled="isVerifyCode">
              <span>發送驗證碼</span>
            </el-button>
          </div>
        </div>
        <div class="code mt-3">
          <label class="red_star">* <span>輸入驗證碼</span></label>
          <div class="d-flex align-items-center mt-2">
            <el-input type="text" v-model="verifyCodeInput" autocomplete="on" class="inner-input" @keydown.enter.prevent="login" :disabled="!isVerifyEmailSend" placeholder="請輸入驗證碼" />
            <el-button :disabled="!isVerifyEmailSend" class="ms-2" @click="verifyCode()">驗證<i class="fa-solid fa-badge-check ms-2" v-if="isVerifyCode"></i></el-button>
          </div>
        </div>
      </div>
      <div class="register_zone login_zone mt-3">
        <div class="register_password">
          <label class="red_star">* <span>設定密碼</span></label>
          <el-form-item prop="password">
            <el-input :disabled="!isVerifyCode" v-model="emailForm.password" type="password" autocomplete="new-password" show-password placeholder="請設定密碼" />
          </el-form-item>
        </div>
        <div class="register_passwordConfirm">
          <label class="red_star">* <span>確認密碼</span></label>
          <el-form-item prop="passwordConfirm">
            <el-input :disabled="!isVerifyCode" v-model="emailForm.passwordConfirm" @keydown.enter.prevent="register" show-password autocomplete="off" placeholder="請再一次輸入密碼" />
          </el-form-item>
        </div>
        <ConfirmButton class="register_btn" @click="register()" text="綁定Email"></ConfirmButton>
      </div>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="verifyEmailClear()">取消</el-button>
        <el-button type="primary" @click="bindEmail()">確認</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import PasswordEdit from "../../../components/Shared/MemberCenter/PasswordEdit.vue";
import PasswordSetting from "../../../components/Shared/MemberCenter/PasswordSetting.vue";
import Storage from "../../../models/storage";
import EditInputButton from "../../../components//Common/CommonElement/EditInputButton.vue";
import EditButtonGroup from "../../../components//Common/EditButtonGroup.vue";
import { googleSdkLoaded } from "vue3-google-login";

const dayjs = require("dayjs");

export default {
  name: "Member",
  components: {
    PasswordEdit,
    PasswordSetting,
    EditInputButton,
    EditButtonGroup,
  },
  data() {
    const validatePassword = (rule, value, callback) => {
      if (!this.isVerifyCode) callback();
      if (value === "") {
        callback(new Error("密碼不可為空"));
      } else {
        if (this.emailForm.passwordConfirm !== "") {
          this.$refs.emailForm.validateField("passwordConfirm");
        }
        callback();
      }
    };
    const validatePasswordConfirm = (rule, value, callback) => {
      if (!this.isVerifyCode) callback();
      if (value === "") {
        callback(new Error("請再次輸入密碼"));
      } else if (value !== this.emailForm.password) {
        callback(new Error("密碼輸入錯誤"));
      } else {
        callback();
      }
    };
    return {
      verifyCodeInput: "",
      isVerifyEmailSend: false,
      isVerifyCode: false,
      activeName: "baseInfo",
      isProfileClicked: false,
      isReadonly: true,
      isShowEmailVerification: true,
      isShowPhoneVerification: true,
      labelPosition: "top",
      backup: {},
      dialogEmailVisible: false,
      dialogPhoneVisible: false,
      dialogOpenEmailVisible: false,
      verifyEmail: "",
      verifyPhone: "",
      membershipExpired: true,
      isProfileClickedArray: [false, false, false, false, false],
      promoCode: "",
      memberLevelObject: {
        Normal: "一般會員",
        Special: "特仕會員",
        Pro: "專業會員",
      },
      ruleForm: {
        imageUrl: "",
        nickName: "",
        name: "",
        birthday: "",
        cellPhone: "",
        email: "",
        registerType: "",
        memberLevel: "",
      },
      emailForm: {
        email: "",
        password: "",
        passwordConfirm: "",
      },
      rules: {
        email: [
          {
            type: "email",
            message: "請輸入正確信箱格式",
            trigger: ["blur", "change"],
          },
          {
            validator: this.validateEmpty,
            trigger: "change",
          },
        ],
        cellPhone: [
          {
            pattern: /^09[0-9]{8}$/,
            message: "請輸入正確電話格式",
            trigger: "change",
          },
        ],
        password: [
          { validator: validatePassword, trigger: "change" },
          {
            pattern: /^(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%]).{8,}$/,
            message: "密碼條件：英文大寫、英文小寫、數字、符號 ! @ # $ %",
            trigger: "change",
          },
        ],
        passwordConfirm: [{ validator: validatePasswordConfirm, trigger: "change" }],
      },
    };
  },
  async created() {
    await this.getMember();
  },
  async mounted() {
    this.log_PageInfo("MemberCenter", "6");
    this.membershipExpired = await this.isMembershipExpired();
    this.initFaceBook();
  },
  methods: {
    sendPromoCode() {
      const vm = this;
      if (vm.promoCode == "") {
        vm.notify("error", "請輸入優惠碼！");
        return;
      }
      const data = {
        PromoCode: vm.promoCode,
      };
      vm.fetchAPI("post", `${process.env.VUE_APP_URL_API}/member/promoCode`, data)
        .then(res => {
          vm.notify("success", "優惠碼使用成功！");
          vm.promoCode = "";
          vm.ruleForm.membershipExpiredDateTime = dayjs(res.data).format("YYYY年 MM月 DD日  HH:mm:ss");
        })
        .catch(err => {
          vm.notify("error", `${err.response.data.Msg}！`);
        });
    },
    initFaceBook() {
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: process.env.VUE_APP_FaceBook_CLIENT_ID,
          cookie: true,
          xfbml: true,
          version: "v19.0",
        });
      };

      (function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = "https://connect.FaceBook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      })(document, "script", "FaceBook-jssdk");
    },
    loginWithFaceBook() {
      window.FB.login(
        response => {
          if (response.authResponse) {
            console.log("成功登录", response.authResponse);
            this.faceBookAuthorize("test");
          } else {
            console.log("登录失败或被取消");
            this.faceBookAuthorize("test");
          }
        },
        { scope: "public_profile,email" }
      );
    },
    async faceBookAuthorize(code) {
      const vm = this;
      if (code == "" || code == null) {
        vm.notify("error", "綁定失敗！");
      }

      let user = vm.$store.state.auth.user;
      let userId = null;
      if (user != null) userId = user.id;

      let par = { AuthCode: code, UserId: userId };

      await vm.$store
        .dispatch("auth/bindFaceBookLogin", par)
        .then(() => {
          vm.notify("success", "綁定成功");
        })
        .catch(() => {
          vm.notify("error", "綁定失敗！");
        });
    },
    loginWithGoogle() {
      googleSdkLoaded(google => {
        google.accounts.oauth2
          .initCodeClient({
            client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
            scope: "email profile openid",
            redirect_uri: `${process.env.VUE_APP_CURRENT_DOMAIN}`,
            callback: response => {
              if (response.code) {
                this.googleAuthorize(response.code);
              }
            },
          })
          .requestCode();
      });
    },
    async googleAuthorize(code) {
      const vm = this;
      if (code == "" || code == null) {
        vm.notify("error", "綁定失敗！");
      }

      let user = vm.$store.state.auth.user;
      let userId = null;
      if (user != null) userId = user.id;

      let par = { AuthCode: code, UserId: userId };

      await vm.$store
        .dispatch("auth/bindGoogleLogin", par)
        .then(() => {
          vm.notify("success", "綁定成功");
        })
        .catch(() => {
          vm.notify("error", "綁定失敗！");
        });
    },
    bindEmail() {
      const vm = this;
      vm.$refs["emailForm"].validate(valid => {
        if (valid) {
          const data = {
            Email: vm.emailForm.email,
            Password: vm.emailForm.password,
          };
          vm.fetchAPI("post", `${process.env.VUE_APP_URL_API}/account/bindEmail`, data)
            .then(() => {
              let user = Storage.get("user");
              user.email = data.Email;
              user.isEmailVerified = true;
              Storage.set("user", user);

              vm.currentUser().email = data.Email;
              vm.currentUser().isEmailVerified = true;
              vm.ruleForm.email = data.Email;

              vm.verifyEmailClear();
              vm.notify("success", "綁定Email成功！");
            })
            .catch(err => {
              console.log(err);
              vm.notify("error", "綁定Email失敗！");
            });
        } else {
          vm.notify("warning", `請確認輸入資料是否正確!`);
        }
      });
    },
    verifyEmailClear() {
      const vm = this;
      vm.verifyCodeInput = "";
      vm.emailForm = {
        email: "",
        password: "",
        passwordConfirm: "",
      };
      vm.isVerifyEmailSend = false;
      vm.isVerifyCode = false;
      vm.dialogOpenEmailVisible = false;
    },
    verifyCode() {
      const vm = this;
      const data = {
        Email: vm.emailForm.email,
        VerifyCode: vm.verifyCodeInput.trim(),
      };

      if (data.VerifyCode == "") {
        vm.notify("error", `請輸入驗證碼`);
        return;
      }

      vm.fetchAPI("post", `${process.env.VUE_APP_URL_API}/account/VerifyCode`, data).then(res => {
        if (res.data.verifySuccess) {
          vm.isVerifyCode = true;
          vm.isVerifyEmailSend = false;
          vm.notify("success", "驗證成功！");
        } else {
          vm.notify("error", `驗證錯誤！`);
        }
      });
    },
    async checkUserByEmail() {
      const vm = this;
      try {
        const res = await vm.fetchAPI("post", `${process.env.VUE_APP_URL_API}/Account/CheckEmail/${vm.emailForm.email}`);
        if (res.data.registerType !== "") {
          vm.notify("error", `此信箱已被註冊`);
          return false;
        }
        return true;
      } catch (err) {
        return false;
      }
    },
    verifyMemberEmail() {
      const vm = this;
      vm.$refs["emailForm"].validate(async valid => {
        if (valid) {
          var isValid = await vm.checkUserByEmail(); //檢查是否有被註冊
          if (!isValid) return;

          const data = {
            Email: vm.emailForm.email,
          };
          vm.fetchAPI("post", `${process.env.VUE_APP_URL_API}/account/verificationByEmail`, data)
            .then(() => {
              vm.isVerifyEmailSend = true;
              vm.notify("success", "驗證信發送成功！");
            })
            .catch(err => {
              console.log(err);
              vm.notify("error", "驗證信發送失敗！");
            });
        } else {
          vm.notify("warning", `請確認輸入資料是否正確!`);
        }
      });
    },
    copyText() {
      navigator.clipboard
        .writeText(this.ruleForm.referralCode)
        .then(() => {
          this.notify("success", "複製推薦碼成功！");
        })
        .catch(() => {
          this.notify("error", "複製推薦碼失敗！");
        });
    },
    resetAvatar() {
      const vm = this;
      vm.$confirm("請確認是否重設頭貼？", "確認", {
        confirmButtonText: "是",
        cancelButtonText: "否",
        type: "warning",
      })
        .then(() => {
          vm.fetchAPI("put", `${process.env.VUE_APP_URL_API}/member/resetAvatar`)
            .then(res => {
              vm.ruleForm.imageUrl = res.data;
              vm.currentUser().avatar = res.data;
              let user = Storage.get("user");
              user.avatar = res.data;
              Storage.set("user", user);
              vm.notify("success", "重設頭貼成功！");
            })
            .catch(err => {
              console.log(err);
              vm.notify("error", "重設頭貼失敗！");
            });
        })
        .catch(() => {
          vm.$message({
            type: "info",
            message: "取消重設頭貼",
          });
        });
    },
    lineLogin() {
      window.location.href = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${process.env.VUE_APP_LINE_CLIENT_ID}&redirect_uri=${process.env.VUE_APP_CURRENT_DOMAIN}LineAuth&state=12345abcde&scope=profile%20openid`;
    },
    showDialogEmail() {
      this.dialogEmailVisible = true;
      this.$nextTick(() => document.querySelector(".verify_input_mail input").focus());
    },
    showDialogPhone() {
      this.dialogPhoneVisible = true;
      this.$nextTick(() => document.querySelector(".verify_input_phone input").focus());
    },
    uploadAvatar(param) {
      const vm = this;
      if (!vm.imageRestrict(param)) {
        vm.notify("error", "圖片格式不支援，僅限 jpg / jpeg / png！");
        return;
      }
      let formData = new FormData();
      formData.append("image", param.file);
      vm.fetchAPI("put", `${process.env.VUE_APP_URL_API}/member/current/avatar`, formData)
        .then(res => {
          vm.ruleForm.imageUrl = res.data;
          vm.currentUser().avatar = res.data;
          let user = Storage.get("user");
          user.avatar = res.data;
          Storage.set("user", user);
          vm.notify("success", "個人頭貼更新成功！");
        })
        .catch(err => {
          console.log(err);
          vm.notify("error", "個人頭貼更新失敗！");
        });
    },
    verifyConfirm(type) {
      const vm = this;
      let data = {
        verificationCode: type === 0 ? vm.verifyEmail : vm.verifyPhone,
        verificationType: type,
      };
      if (data.verificationCode !== "")
        vm.fetchAPI("put", `${process.env.VUE_APP_URL_API}/account/verification`, data)
          .then(() => {
            vm.notify("success", "驗證信(簡訊)發送成功！");
            if (type === 0) {
              vm.verifyEmail = "";
              vm.dialogEmailVisible = false;
              vm.isShowEmailVerification = false;
            } else {
              vm.verifyPhone = "";
              vm.dialogPhoneVisible = false;
              vm.isShowPhoneVerification = false;
            }
          })
          .catch(err => {
            console.log(err);
            vm.notify("error", `${err.response.data.Msg}！`);
          });
    },
    verifyCancel(type) {
      const vm = this;
      if (type === 0) {
        vm.verifyEmail = "";
        vm.dialogEmailVisible = false;
      } else {
        vm.verifyPhone = "";
        vm.dialogPhoneVisible = false;
      }
    },
    verifyMember(type) {
      const vm = this;
      if (vm.ruleForm.email == "" && type === 0) {
        vm.notify("error", "請先填入Email！");
        return;
      } else if (vm.ruleForm.cellPhone == "" && type === 1) {
        vm.notify("error", "請先填入手機號碼！");
        return;
      }
      vm.fetchAPI("post", `${process.env.VUE_APP_URL_API}/account/verification/${type}`)
        .then(() => {
          vm.notify("success", "驗證信(簡訊)發送成功！");
          setTimeout(() => {
            vm.dialogEmailVisible = true;
          }, 1500);
        })
        .catch(err => {
          console.log(err);
          vm.notify("error", "驗證信(簡訊)發送失敗！");
        });
    },
    async getMember() {
      const vm = this;
      vm.fetchAPI("get", `${process.env.VUE_APP_URL_API}/Member/current`)
        .then(res => {
          vm.ruleForm = {
            nickName: res.data.nickName,
            name: res.data.name,
            cellPhone: res.data.cellPhone,
            email: res.data.email,
            birthday: vm.turnBirthdayType(res.data.birthday),
            imageUrl: res.data.avatar,
            registerType: res.data.registerType,
            lineBotPoint: res.data.lineBotPoint,
            lineBotRequestCount: res.data.lineBotRequestCount,
            chargeMePoint: res.data.chargeMePoint,
            referralCode: res.data.referralCode,
            isBuyMembership: res.data.isBuyMembership,
            isPasswordSetting: res.data.isPasswordSetting,
            membershipExpiredDateTime: dayjs(res.data.membershipExpiredDateTime).format("YYYY年 MM月 DD日  HH:mm:ss"),
            memberLevel: res.data.memberLevel,
          };
          if (res.data.isEmailVerified) vm.isVerifyEmailSuccess = true;
          if (res.data.isPhoneVerified) vm.isShowPhoneVerification = false;
        })
        .catch(err => {
          console.log(err);
        });
    },
    changeToEdit(index) {
      Object.assign(this.backup, this.ruleForm);
      this.isProfileClickedArray = [false, false, false, false, false];
      this.isProfileClickedArray[index] = true;
      this.$refs[`autoFocus${index}`].focus();
    },
    changeToOrigin(index) {
      this.$refs["ruleForm"].resetFields();
      Object.assign(this.ruleForm, this.backup);
      this.isProfileClickedArray[index] = false;
    },
    editCompleted(index) {
      const vm = this;
      vm.$refs["ruleForm"].validate(valid => {
        if (valid) {
          let data = {
            Id: vm.currentUser().id,
            Name: vm.ruleForm.name,
            NickName: vm.ruleForm.nickName,
            CellPhone: vm.ruleForm.cellPhone,
            Email: vm.ruleForm.email,
            Birthday: vm.turnBirthdayType(vm.ruleForm.birthday),
          };
          vm.fetchAPI("put", `${process.env.VUE_APP_URL_API}/Member/current`, data)
            .then(res => {
              if (res.data === true) {
                vm.notify("success", "個人資料更新成功！");
                vm.currentUser().name = vm.ruleForm.name;
                let user = Storage.get("user");
                user.name = vm.ruleForm.name;
                Storage.set("user", user);
              }
            })
            .catch(err => {
              console.log(err);
              vm.$refs["ruleForm"].resetFields();
              Object.assign(vm.ruleForm, vm.backup);

              vm.notify("error", `個人資料更新失敗，${err.response.data.Msg}！`);
            })
            .finally(() => {
              vm.isProfileClickedArray[index] = false;
            });
        } else {
          vm.notify("warning", `請確認輸入資料是否正確!`);
        }
      });
    },
  },
  computed: {
    birthday() {
      return dayjs(this.ruleForm.birthday).format("YYYY-MM-DD");
    },
  },
};
</script>

<style lang="scss" scoped>
.member {
  text-align: start;
  padding: 0 1.5rem 1rem;
  .edit_button {
    background-color: $main-color;
    border-radius: 0.5rem;
    padding: 0.3rem;
    border: none;
    transition: $main_transition;
    span {
      color: #fff;
      font-size: 1rem;
      transition: $main_transition;
      font-weight: $main_fontWeight;
    }
    &:hover {
      background-color: $main_color_hover;
    }
  }
  .main {
    :deep .el-form-item {
      border-bottom: 1px solid rgb(183, 183, 183);
      .el-form-item__content {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: space-between;
        .el-input.disActive {
          .el-input__inner {
            cursor: default;
          }
          .el-input__wrapper {
            box-shadow: none;
          }
        }
        .edit_group {
          flex-grow: 1;
          width: 20rem;
        }
      }
    }
    .main_data {
      display: flex;
      justify-content: center;
      width: 100%;
      .avatar_section {
        // margin-top: 1.5rem;
        width: 100%;
        .label {
          margin-bottom: 8px;
          line-height: 22px;
          font-size: 1.1rem;
          font-weight: 900;
          color: rgba(0, 0, 0, 0.55);
        }
      }
      :deep .avatar-uploader {
        display: flex;
        justify-content: center;
        .el-upload {
          border: 1px dashed #d9d9d9;
          border-radius: 6px;
          cursor: pointer;
          position: relative;
          overflow: hidden;
          margin: auto;
          &:hover {
            border-color: #409eff;
          }
          .avatar-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: 18rem;
            height: 18rem;
            line-height: 18rem;
            text-align: center;
          }
          img {
            object-fit: contain;
          }
        }
      }
      .avatar {
        width: 18rem;
        height: 18rem;
        display: block;
      }
    }
  }
}
.verify_success {
  color: rgb(20, 145, 20);
}
.edit_group {
  display: flex;
  justify-content: flex-end;
}
.email_warning {
  text-align: start;
  margin-top: 2rem;
  color: red;
}
.v-enter-active,
.v-leave-active {
  transition: opacity $main_transition;
}
.v-enter-from,
.v-leave-to {
  opacity: 0;
}
.v-enter-to,
.v-leave-from {
  opacity: 1;
}
label,
.referralCodeURL {
  margin-bottom: 8px;
  line-height: 22px;
  font-size: 1.1rem;
  font-weight: 900;
  color: rgba(0, 0, 0, 0.55);
  display: flex;
  align-items: center;
  margin-right: 1rem;
  .red_notice {
    font-size: 1rem;
    color: red;
    margin-left: 0.5rem;
  }
}
.avatar_img {
  margin-bottom: 0 !important;
}
.reset {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.line_btn,
.reset_btn {
  width: 6rem;
  margin-top: 1rem;
}
.birthdayText {
  margin-left: 0.8rem;
  width: 8rem;
  font-size: 1rem;
  color: #606266;
  i {
    font-size: 0.8rem;
    margin-right: 0.8rem;
  }
}
.onlyText span {
  font-size: 1rem;
  padding-left: 1rem;
  width: 100%;
  display: inline-block;
  word-wrap: break-word;
}
.memberShipExpired {
  span.expired {
    color: red;
    font-weight: bold;
  }
}
.referralCodeURL {
  font-weight: bold;
}
.red_star {
  color: red !important;
  text-align: start;
  span {
    font-size: 1.1rem;
    font-weight: 900;
    color: rgba(0, 0, 0, 0.55);
  }
}
.other_login {
  display: flex;
  .icon_part {
    border-radius: 50%;
    box-shadow: 1px 1px 3px 1px #b0b0b0;
    overflow: hidden;
    width: 2.5rem;
    height: 2.5rem;
    transition: 0.2s;
    margin-right: 1.5rem;
    &:hover {
      cursor: pointer;
      box-shadow: 1px 1px 3px 1px #565656;
    }
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}
.verified {
  position: relative;
}

.verified i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 900;
  font-size: 1.5rem;
}

.verified img {
  opacity: 0.5; /* Set the desired opacity for the image */
}

@media screen and (min-width: 768px) {
  .member {
    .main {
      display: flex;
      margin-top: 2rem;
      .infoSection {
        width: 100%;
        margin-left: 3rem;
      }
      .main_data {
        justify-content: flex-start;
        flex-direction: column;
        width: 100%;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .avatar_section {
    margin-top: 1.5rem;
  }
}
</style>
