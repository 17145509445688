<template>
  <div>
    <el-button type="danger" @click="cancel">取消</el-button>
    <el-button type="primary" @click="confirm">確認</el-button>
  </div>
</template>

<script>
export default {
  name: "EditButtonGroup",
  methods: {
    cancel() {
      this.$emit("cancel", "ruleForm");
    },
    confirm() {
      this.$emit("confirm", "ruleForm");
    },
  },
  props: ["showButton"],
};
</script>
